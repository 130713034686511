<template>
  <div
    id="accordion"
    data-accordion="open"
    data-active-classes="bg-white text-gray-900 rounded-lg md:rounded-2xl"
    data-inactive-classes="text-gray-500">
    <div v-if="firstAccordionItem" class="mb-1 rounded-lg bg-white p-4 last:mb-0 md:rounded-2xl md:px-6 md:py-4">
      <h2 :id="`accordion-heading-top-${firstAccordionItem.id}`">
        <button
          type="button"
          class="flex w-full items-center justify-between bg-white text-left font-medium text-gray-900"
          :data-accordion-target="`#accordion-body-top-${firstAccordionItem.id}`"
          :aria-controls="`accordion-body-top-${firstAccordionItem.id}`"
          aria-expanded="true">
          <span class="font-normal text-gray-900 md:text-2xl">{{ firstAccordionItem.title }}</span>
          <span data-accordion-icon="true">
            <SvgoChevronRight class="-rotate-90 text-lg text-gray-900" />
          </span>
        </button>
      </h2>
      <div
        :id="`accordion-body-top-${firstAccordionItem.id}`"
        class=""
        :aria-labelledby="`accordion-heading-top-${firstAccordionItem.id}`">
        <div class="js-content my-2 text-sm text-gray-900 md:text-base" v-html="firstAccordionItem.content" />
      </div>
    </div>

    <div v-if="testimonials.length > 0" class="mb-1 rounded-lg bg-white p-4 last:mb-0 md:rounded-2xl md:px-6 md:py-4">
      <h2 id="accordion-heading-testimonials">
        <button
          type="button"
          class="flex w-full items-center justify-between bg-white text-left font-medium text-gray-900"
          data-accordion-target="#accordion-body-testimonials"
          aria-expanded="false"
          aria-controls="accordion-body-testimonials">
          <span class="font-normal text-gray-900 md:text-2xl">Testimonials</span>
          <span data-accordion-icon="true">
            <SvgoChevronRight class="-rotate-90 text-lg text-gray-900" />
          </span>
        </button>
      </h2>
      <div id="accordion-body-testimonials" class="hidden" aria-labelledby="accordion-heading-testimonials">
        <div v-for="item in testimonials" :key="item.id">
          <SharedDocfinderRating v-if="item.rating" :rating="item.rating" />
          <span> date </span>
          <!-- TODO: Format date to dd.mm.yyyy -->
          <p>{{ item.attributes.content }}</p>
        </div>
      </div>
    </div>

    <div
      v-for="item in remainingAccordionItems"
      :key="item.id"
      class="mb-1 rounded-lg bg-white p-4 last:mb-0 md:rounded-2xl md:px-6 md:py-4">
      <h2 :id="`accordion-heading-${item.id}`">
        <button
          type="button"
          class="flex w-full items-center justify-between bg-white text-left font-medium text-gray-900"
          :data-accordion-target="`#accordion-body-${item.id}`"
          :aria-controls="`accordion-body-${item.id}`"
          aria-expanded="false">
          <span class="font-normal text-gray-900 md:text-2xl">{{ item.title }}</span>
          <span data-accordion-icon="true">
            <SvgoChevronRight class="-rotate-90 text-lg text-gray-900" />
          </span>
        </button>
      </h2>
      <div :id="`accordion-body-${item.id}`" class="hidden" :aria-labelledby="`accordion-heading-${item.id}`">
        <div class="js-content my-2 text-sm text-gray-900 md:text-base" v-html="item.content" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { initAccordions } from "flowbite";
import { computed, onMounted } from "vue";

const props = withDefaults(
  defineProps<{
    details: any[];
    testimonials: any[];
  }>(),
  {
    details: () => [],
    testimonials: () => [],
  },
);

onMounted(() => {
  initAccordions();
});

const firstAccordionItem = computed(() => {
  return props.details[0];
});

const remainingAccordionItems = computed(() => {
  return props.details.slice(1);
});

const testimonials = computed(() => {
  return props.testimonials || [];
});
</script>

<style scoped>
.js-content :deep(a) {
  @apply underline md:no-underline md:hover:underline;
}
.js-content :deep(p) {
  @apply mb-2;
}
.js-content :deep(ul) {
  @apply mb-3 list-outside list-disc pl-5;
}
.js-content :deep(ul) > li > ul {
  @apply pl-5;
}
</style>
